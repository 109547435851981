import { useCallback, useEffect } from 'react';
import { WithConfirmationWrappedComponentProps } from '../../utils/withConfirmation';
import { useEmployeesOverview } from './EmployeesOverview';
import { useTranslation } from 'react-i18next';
import { deleteUser } from '../../lib/slices/usersSlice';
import { useAppDispatch, useUsers } from '../../lib/hooks';
import { FETCH_STATE } from '../../lib/slices/types';
import { setLoader } from '../../lib/slices/globalLoaderSlice';
import { useNotificationsSnackbar } from '../../components/snackbar/NotificationsSnackbarContext';

export const useEmployeeStatusConfirmation = (
    showConfirmDialog: WithConfirmationWrappedComponentProps['showConfirmDialog'],
    reloadUsers: () => void
) => {
    const {
        onUpdateSelectedClick,
        onUserChangeStatusClick,
        onRoleToggleChange,
        onSendActivationMailClicked
    } = useEmployeesOverview();

    const { setAlert } = useNotificationsSnackbar();

    const handleBulkEnable = (checked: string[]) => {
        showConfirmDialog(
            'Do you really want to activate the users?',
            null,
            () => {
                onUpdateSelectedClick(true, checked, reloadUsers);
            }
        );
    };

    const handleBulkDisable = (checked: string[]) => {
        showConfirmDialog(
            'Do you really want to disable the users?',
            null,
            () => {
                onUpdateSelectedClick(false, checked, reloadUsers);
            }
        );
    };

    const handleStatusChange = (
        userId: string,
        isActive: boolean,
        reloadUsers: () => void
    ) => {
        showConfirmDialog(
            !isActive
                ? 'Do you really want to disable the user?'
                : 'Do you really want to activate the user?',
            null,
            () => {
                onUserChangeStatusClick(userId, isActive, reloadUsers);
            }
        );
    };

    const handleRoleToggleChange = (
        userId: string,
        isAdmin: boolean,
        reloadUsers: () => void
    ) => {
        showConfirmDialog(
            isAdmin
                ? 'do you really want to assign admin access?'
                : 'do you really want to remove admin access?',
            null,
            () => {
                onRoleToggleChange(userId, isAdmin, reloadUsers);
            }
        );
    };

    const sendActivationEmail = (
        userId: string,
        name: string,
        setError: any
    ) => {
        showConfirmDialog(
            `Are you sure that you want to send a new activation mail to ${name} ?`,
            'Send activation mail',
            () => {
                onSendActivationMailClicked(userId, setError);
            }
        );
    };

    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const handleDeleteUser = (userId: string) => {
        showConfirmDialog(
            t('delete_user_confirm_message'),
            t('delete_user'),
            () => dispatch(deleteUser(userId))
        );
    };

    const { deleteUser: deleteUserState } = useUsers();

    useEffect(() => {
        dispatch(setLoader(deleteUserState.status == FETCH_STATE.LOADING));
        if (deleteUserState.status === FETCH_STATE.SUCCEEDED) {
            setAlert(t('delete_user_success_message'), 'success');
        } else if (deleteUserState.status === FETCH_STATE.FAILED) {
            setAlert(t('delete_user_failed_message'), 'error');
        }
        reloadUsers();
    }, [deleteUserState]);

    return {
        handleBulkEnable,
        handleBulkDisable,
        handleStatusChange,
        handleRoleToggleChange,
        sendActivationEmail,
        handleDeleteUser
    } as const;
};
